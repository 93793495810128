import KooClassImg from 'assets/img/kooclass.svg';
import NotificationAdminImg from 'assets/img/notification.jpg';
import SchoolAdminImg from 'assets/img/school-admin.jpg';
import ContingencyAdmin from 'assets/img/contingency_admin.jpg';
import {
  checkNotificationAdminRole,
  checkAdminOrContentRole,
  checkSchoolAdminRole,
  checkAdminRoleById,
} from 'helpers/roleChecker';

const portals = [
  {
    id: 1,
    name: 'KooClass Admin',
    thumbnail: KooClassImg,
    url: process.env.REACT_APP_KOOCLASS_ADMIN_URL,
    roleChecker: checkAdminOrContentRole,
  },
  {
    id: 2,
    name: 'Notification Admin',
    thumbnail: NotificationAdminImg,
    url: process.env.REACT_APP_NOTIFICATION_ADMIN_URL,
    roleChecker: checkNotificationAdminRole,
  },
  {
    id: 3,
    name: 'School Admin',
    thumbnail: SchoolAdminImg,
    url: process.env.REACT_APP_SCHOOL_ADMIN_URL,
    roleChecker: checkSchoolAdminRole,
  },
  {
    id: 4,
    name: 'Contingency Admin',
    thumbnail: ContingencyAdmin,
    url: process.env.REACT_APP_CONTINGENCY_ADMIN_URL,
    roleChecker: checkAdminRoleById(110),
  },
  {
    id: 5,
    name: 'Koob Acquisition Admin',
    thumbnail: KooClassImg,
    url: process.env.REACT_APP_TRIAL_ADMIN_URL,
    roleChecker: checkAdminRoleById(112),
  },
];

export default portals;
