import { Center, Heading } from '@chakra-ui/react';
import LoginForm from 'components/Login/LoginForm';

const LoginPage = () => {
  return (
    <Center minH="100vh" w="100%" display="flex" flexDir="column">
      <Heading mb="1rem">KooBits Admin</Heading>
      <LoginForm />
    </Center>
  );
};

export default LoginPage;
