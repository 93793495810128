import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from 'pages/Login';
import { Suspense, lazy } from 'react';

const PortalSelection = lazy(() => import('./pages/PortalSelection'));

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<div />}>
          <Route exact path="/portals">
            <PortalSelection />
          </Route>
          <Route exact path="/">
            <Login />
          </Route>
        </Suspense>
      </Switch>
    </Router>
  );
};

export default Routes;
