import {
  VStack,
  FormControl,
  Input,
  FormLabel,
  InputGroup,
  Button,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import getToken from 'services/login';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import portals from 'constants/portals';
import jwt_decode from 'jwt-decode';

const LoginForm = () => {
  const toast = useToast();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Event Handlers
  const handleClick = () => setShow(!show);
  const handleLogin = async (e) => {
    e.preventDefault();
    const loginID = e.target.loginid.value;
    const password = e.target.password.value;
    setIsSubmitting(true);
    try {
      const res = await getToken({
        username: loginID,
        password,
      });
      // Check roles
      const decoded = jwt_decode(res.access_token);
      const { role } = decoded;
      const roles = JSON.parse(role);
      const hasAccess = portals.some(
        (portal) => portal.roleChecker(roles) === true
      );
      if (!hasAccess) {
        throw new Error('Your account does not have the correct role');
      }
      Cookies.set('ia_access_token', res.access_token, {
        path: '/',
        domain: 'koobits.com',
      });
      Cookies.set('ia_refresh_token', res.refresh_token, {
        path: '/',
        domain: 'koobits.com',
      });
      history.push('/portals');
    } catch (err) {
      console.log(err.message);
      toast({
        title: `Error: ${err.message}`,
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <VStack spacing="0.5rem">
      <form onSubmit={handleLogin}>
        <FormControl id="loginID" isRequired>
          <FormLabel>Login ID</FormLabel>
          <Input placeholder="Enter your login ID" name="loginid" />
        </FormControl>
        <VStack spacing="2rem">
          <InputGroup size="lg">
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                pr="4.5rem"
                type={show ? 'text' : 'password'}
                placeholder="Enter your password"
                name="password"
              />
            </FormControl>
            <InputRightElement width="4.5rem" top="28px">
              <Button size="sm" onClick={handleClick}>
                {show ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button
            size="md"
            w="100%"
            colorScheme="orange"
            isLoading={isSubmitting}
            type="submit"
          >
            Login
          </Button>
        </VStack>
      </form>
    </VStack>
  );
};

export default LoginForm;
