import { isNil } from 'ramda';

const getToken = ({ username, password }) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'password');
  urlencoded.append('username', username);
  urlencoded.append('password', password);
  urlencoded.append('client_id', process.env.REACT_APP_CLIENT_ID);
  urlencoded.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
  urlencoded.append('scope', process.env.REACT_APP_CLIENT_SCOPE);
  return fetch(`${process.env.REACT_APP_TOKEN_URL}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: urlencoded,
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      if (!isNil(json.error)) {
        throw new Error(json.error_description);
      } else {
        return json;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Login failed');
    });
};

export default getToken;
