export const checkNotificationAdminRole = (roles) => {
  return roles.some((role) => role.Id === 105);
};

export const checkSchoolAdminRole = (roles) => {
  return roles.some((role) => role.Id === 106);
};

export const checkAdminOrContentRole = (roles) => {
  return roles.some(
    (role) => role.Name === 'Admin' || role.Name === 'ContentUpload'
  );
};

export const checkAdminRoleById = (roleId) => {
  return (roles) => {
    return roles.some((role) => role.Id === roleId);
  };
};
